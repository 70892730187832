import { EmptyPageLoader } from 'components/Loader'

export default function CommonContextsWrapper({ children }) {
  if (typeof window == 'undefined') {
    // In the future we may want to pre-render some of the page
    // on the server to speed up loading, but not for now.
    return <EmptyPageLoader />
  } else {
    return <>{children}</>
  }
}

import React, { useEffect, useRef } from 'react'
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-yaml'
import 'prismjs/components/prism-http'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-tsx'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-go'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-ruby'

import 'prismjs/components/prism-markup-templating'
import 'prismjs/components/prism-php' // NOTE: php is dependant on prism-markup-templating, do not remove the line above

import pluginAutoDocRefsPlugin from './plugin-autoDocRefs'
import clsx from 'clsx'
import classes from './styles.module.css'
import CopyToClipboard from '../CopyToClipboard'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'

Prism.manual = true
pluginAutoDocRefsPlugin(Prism)

export enum CodeHighlighterLanguages {
  'atom' = 'atom',
  'bash' = 'bash',
  'clike' = 'clike',
  'css' = 'css',
  'html' = 'html',
  'http' = 'http',
  'javascript' = 'javascript',
  'js' = 'js',
  'jsx' = 'jsx',
  'markup' = 'markup',
  'mathml' = 'mathml',
  'plain' = 'plain',
  'plaintext' = 'plaintext',
  'rss' = 'rss',
  'sh' = 'sh',
  'shell' = 'shell',
  'ssml' = 'ssml',
  'svg' = 'svg',
  'text' = 'text',
  'tsx' = 'tsx',
  'txt' = 'txt',
  'typescript' = 'typescript',
  'xml' = 'xml',
  'yaml' = 'yaml',
  'yml' = 'yml',
  'go' = 'go',
  'python' = 'python',
  'java' = 'java',
  'php' = 'php',
  'ruby' = 'ruby',
}

export interface ICodeHighlighter {
  code: string
  className?: string
  language?: string
  copyToClipboard?: boolean
}

export default function CodeHighlighter({
  code,
  className,
  language = 'shell',
  copyToClipboard,
}: ICodeHighlighter) {
  const ref = useRef()

  const validLanguage = getValidLanguage(language)

  useEffect(() => {
    if (!ref.current || !code) return
    Prism.highlightAllUnder(ref.current)
  }, [ref.current, code])

  return (
    <div
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
      ref={ref}
      className={clsx(
        'Code',
        'line-numbers',
        classes.wrapper,
        copyToClipboard && classes.wrapper__withCopyToClipboard,
        className,
      )}
    >
      {copyToClipboard && (
        <div className={classes.copyToClipboard}>
          <CopyToClipboard
            value={code}
            confirmationText={<SvgIcon type={SvgIconType.Check} />}
            timeout={1500}
          >
            <button className={classes.copyToClipboard_button}>
              <span>Copy</span> <SvgIcon type={SvgIconType.Copy} />
            </button>
          </CopyToClipboard>
        </div>
      )}
      <pre
        tabIndex={-1}
        className={clsx(`language-${validLanguage}`, getDigitsClassaname(code))}
      >
        <code className={`language-${validLanguage}`}>{code.trimEnd()}</code>
      </pre>
    </div>
  )
}

function getValidLanguage(language: string) {
  const lang = language.toLowerCase()
  return CodeHighlighterLanguages[lang] || CodeHighlighterLanguages.markup
}

function getDigitsClassaname(code: string) {
  const linesCount = code.split('\n').length
  switch (true) {
    case linesCount < 10:
      return classes.digits_1
    case linesCount < 100:
      return classes.digits_2
    case linesCount < 1000:
      return classes.digits_3
    case linesCount < 10000:
      return classes.digits_4
    default:
      return classes.digits_5
  }
}

const integrationAppClientAccessors = [
  'flows',
  'flowInstances',
  'dataSources',
  'dataSourceInstances',
  'fieldMappings',
  'fieldMappingInstances',
  'dataLinkTables',
  'dataLinkTableInstances',
  'integrations',
  'connections',
  'appEventTypes',
  'appEventSubscriptions',
  'flowRuns',
  'appDataCollections',
  'appDataCollectionInstances',
  'appDataSchemas',
  'appDataSchemaInstances',
]

const integrationAppClientAccessorLinks = integrationAppClientAccessors.reduce(
  (result, func) => {
    return {
      ...result,
      [func]: `https://console.integration.app/ref/sdk/classes/IntegrationAppClient.html#${func}`,
    }
  },
  {},
)
export { integrationAppClientAccessors, integrationAppClientAccessorLinks }

const integrationAppClientFunctions = [
  'flow',
  'flowInstance',
  'dataSource',
  'dataSourceInstance',
  'fieldMapping',
  'fieldMappingInstance',
  'dataLinkTable',
  'dataLinkTableInstance',
  'integration',
  'connection',
  'dataLocation',
  'appEventType',
  'appEventSubscription',
  'flowRun',
  'appDataCollection',
  'appDataCollectionInstance',
  'appDataSchema',
  'appDataSchemaInstance',
  'init',
  'subscribeToChannel',
  'unsubscribeFromChannel',
  'getEmbedUri',
  'getPusherInstance',
  'connectionRequest',
]

const integrationAppClientFunctionLinks = integrationAppClientFunctions.reduce(
  (result, func) => {
    return {
      ...result,
      [func]: `https://console.integration.app/ref/sdk/classes/IntegrationAppClient.html#${func}`,
    }
  },
  {},
)

export { integrationAppClientFunctions, integrationAppClientFunctionLinks }

import {
  integrationAppClientFunctions,
  integrationAppClientFunctionLinks,
  integrationAppClientAccessors,
  integrationAppClientAccessorLinks,
} from './docRefLinks'

const makeWrapper = (tokenList, tokenLinkMap) => (env) => {
  if (!tokenList.includes(env.content)) {
    return
  }

  env.tag = 'a'
  env.attributes.href = tokenLinkMap[env.content]
}

const ACCESSOR_TOKEN = 'accessor'

const addLinkToFunction = makeWrapper(
  integrationAppClientFunctions,
  integrationAppClientFunctionLinks,
)
const addLinkToAccessors = makeWrapper(
  integrationAppClientAccessors,
  integrationAppClientAccessorLinks,
)

function addLinksToSDK(env) {
  if (env.type === ACCESSOR_TOKEN) {
    return addLinkToAccessors(env)
  }
  if (env.type === 'function') {
    return addLinkToFunction(env)
  }
  return
}

export default function pluginAutoDocRefs(Prism) {
  if (typeof Prism === 'undefined') {
    return
  }

  Prism.plugins.autoDocRefs = true
  /**
   * Add Accessor token to highlighter
   */
  const accessorRegexWrapper = new RegExp(
    `\\.(${integrationAppClientAccessors.join('|')})`,
  )
  const accessorRegex = new RegExp(integrationAppClientAccessors.join('|'))

  ;['js', 'jsx', 'ts', 'tsx', 'javascript'].forEach((lang) => {
    Prism.languages.insertBefore(lang, 'punctuation', {
      [`${ACCESSOR_TOKEN}_wrapper`]: {
        pattern: accessorRegexWrapper,
        inside: {
          punctuation: Prism.languages[lang].punctuation,
          [ACCESSOR_TOKEN]: accessorRegex,
        },
      },
    })
  })

  Prism.hooks.add('wrap', addLinksToSDK)
}
